import React from "react";
import { Slide } from "react-awesome-reveal";
import SectionTitle from "../SectionTitle";
import timg1 from '../../images/team/dress-code-men.jpeg.jpg'
import timg2 from '../../images/team/dress-code-women.jpeg'
import timg3 from '../../images/team/img-3.jpg'
import timg4 from '../../images/team/img-4.jpg'


const Brides = [
  {
    id: '1',
    tImg: timg1,
    name: 'Hombres',
    requirement1: 'Traje o smoking',
    requirement2: 'Zapato de vestir o tenis',
    animation:'1000',
    viewBox: '0 0 265 236',
    svgPath: 'M151.285 236C133.89 236 115.923 232.537 98.1199 225.675C71.5047 215.399 46.7662 197.903 28.4734 176.424C-1.79698 140.848 -8.47116 96.8976 11.0455 61.7114C28.4897 30.2812 62.954 7.30616 100.992 1.76157C133.694 -3.00254 166.95 1.97295 194.643 15.7612C224.44 30.6064 246.225 54.5083 257.647 84.8978C268.434 113.596 267.308 146.181 254.563 174.31C251.642 180.749 247.905 186.944 243.45 192.716C242.896 193.432 241.884 193.562 241.166 193.009C240.448 192.456 240.317 191.448 240.872 190.733C245.18 185.156 248.787 179.172 251.61 172.977C264.012 145.628 265.105 113.938 254.612 86.0523C232.893 28.3463 169.92 -4.98624 101.465 4.98101C64.4063 10.3793 30.8559 32.7202 13.9012 63.2886C-4.94641 97.2553 1.59722 139.791 30.9538 174.31C69.5139 219.626 131.54 242.065 181.784 228.894C192.081 226.195 201.937 222.082 211.108 216.683C211.891 216.228 212.886 216.472 213.343 217.252C213.8 218.033 213.539 219.025 212.772 219.48C203.356 225.041 193.206 229.268 182.616 232.049C172.515 234.683 162.006 236 151.285 236ZM228.258 207.984C227.801 207.984 227.344 207.789 227.034 207.432C226.447 206.749 226.512 205.724 227.197 205.139C230.869 201.952 234.312 198.521 237.413 194.944C238 194.261 239.028 194.196 239.714 194.781C240.399 195.367 240.464 196.391 239.877 197.074C236.662 200.781 233.121 204.326 229.335 207.594C229.025 207.854 228.633 207.984 228.258 207.984Z'
  },
  {
    id: '2',
    tImg: timg2,
    name: 'Mujeres',
    requirement1: 'Vestido formal',
    requirement2: 'Tacones o tenis',
    animation:'1200',
    viewBox: '0 0 261 241',
    svgPath: 'M59.0013 241C58.546 241 58.0744 241 57.619 241C56.7246 241 55.9928 240.252 56.0091 239.358C56.0091 238.463 56.7409 237.748 57.6353 237.748H57.6516C74.4181 237.927 91.7375 235.243 108.488 232.658C113.773 231.845 118.782 231.08 123.677 230.413C163.666 224.982 197.459 216.721 227.008 205.142C250.458 195.97 261.126 174.585 255.532 147.963C254.15 141.361 251.808 134.774 248.588 128.383C247.856 126.936 247.108 125.489 246.344 124.041C245.921 123.244 246.23 122.269 247.027 121.846C247.824 121.423 248.799 121.732 249.222 122.529C250.003 123.992 250.751 125.456 251.499 126.92C254.849 133.571 257.288 140.417 258.72 147.296C264.672 175.609 253.255 198.36 228.195 208.167C198.402 219.827 164.349 228.153 124.116 233.633C119.253 234.3 114.261 235.064 108.992 235.878C92.5669 238.398 75.6052 241 59.0013 241ZM42.43 239.959C42.3649 239.959 42.2836 239.959 42.2023 239.943C37.3399 239.293 32.7213 238.301 28.4606 237.016C18.5731 234.024 11.2388 227.974 6.65277 219.014C-8.73144 188.993 9.90524 134.579 18.8658 108.429L19.3862 106.901C20.2969 104.234 21.2401 101.42 22.2159 98.4931C36.3641 56.2109 60.0259 -14.5467 117.66 2.67517C160.3 15.4086 203.086 50.4378 235.09 98.7533C238.635 104.104 242.018 109.6 245.124 115.064C245.563 115.845 245.303 116.837 244.522 117.276C243.742 117.715 242.75 117.455 242.311 116.674C239.237 111.259 235.887 105.827 232.391 100.542C200.809 52.8446 158.657 18.3033 116.749 5.78129C62.1237 -10.5299 39.9581 55.7068 25.3057 99.5339C24.3137 102.477 23.3705 105.307 22.4598 107.974L21.9394 109.503C13.8245 133.213 -5.2025 188.766 9.54747 217.55C13.7106 225.681 20.3945 231.194 29.4038 233.926C33.5019 235.162 37.9578 236.122 42.6414 236.74C43.5358 236.853 44.1538 237.683 44.04 238.577C43.9261 239.374 43.2268 239.959 42.43 239.959Z'
  },
]

const BrideGrooms = (props) => {
  return (
    <section className={`wpo-team-section section-padding ${props.brClass}`}>
      <div className="container">
        <SectionTitle subTitle={'Etiqueta'} MainTitle={'Código de vestimenta'} />
        <div className="wpo-team-wrap scene-2" id="scene-2">
          <div className="row">
            {Brides.slice(0, 2).map((Bride, tm) => (
              <div className="col col-lg-6 col-md-6 col-sm-6 col-12 layer" key={tm}>
                <Slide direction="up" duration={Bride.animation} triggerOnce="true">
                  <div className="wpo-team-item">
                    <div className="wpo-team-img-wrap">
                      <div className="wpo-team-img">
                        <img src={Bride.tImg} alt="" />
                      </div>
                      <div className="team-shape">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox={Bride.viewBox} fill="none">
                          <path
                            d={Bride.svgPath}
                            fill="" />
                        </svg>
                      </div>
                    </div>
                    <div className="wpo-team-text">
                      <h3>{Bride.name}</h3>
                      <span>{Bride.requirement1}</span>
                      <br/>
                      <span>{Bride.requirement2}</span>
                    </div>
                  </div>
                </Slide>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>

  )
}

export default BrideGrooms;