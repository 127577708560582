import React from 'react'
const { useState, useEffect } = React;

const TimeCountDown = (props) => {
  const [countdownDate] = useState(new Date('12/14/2023').getTime());
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  return (
    <div className="react-countdown">
      <div className='time-section'>
        <div className='time'>{state.days || '0'}</div>
        <small className="time-text">Días</small>
      </div>
      <div className='time-section'>
        <div className='time'>{state.hours || '00'}</div>
        <small className="time-text">Horas</small>
      </div>
      <div className='time-section'>
        <div className='time'>{state.minutes || '00'}</div>
        <small className="time-text">Min</small>
      </div>
      <div className='time-section'>
        <div className='time'>{state.seconds || '00'}</div>
        <small className="time-text">Seg</small>
      </div>
    </div>
  );
};

export default TimeCountDown;

