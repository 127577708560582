import React, { Fragment, useState,useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero4 from '../../components/hero4/hero4';
import StorySection4 from '../../components/StorySection4/StorySection4';
import PortfolioSectionS2 from '../../components/PortfolioSectionS2/PortfolioSectionS2';
import EventSectionS3 from '../../components/EventSection3/EventSection3';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import CoupleSection3 from '../../components/CoupleSection3/CoupleSection3';
import RSVPForm from '../../components/RsvpWeddingDH/RsvpWeddingDH';
import BrideGrooms from '../../components/BrideGrooms';
import ContactPage from '../ContactPage/ContactPage';
import RSVPMainForm from '../../components/RsvpSection/RsvpSection'

const HomePage4 = () => {
    return (
        <Fragment>
            <Hero4 />
            <CoupleSection3 />
            <StorySection4 />
            <PortfolioSectionS2 />
            <BrideGrooms brClass={'pt-0'}/>
            <EventSectionS3/>
            <Scrollbar />
            <ContactPage />
            <RSVPMainForm />
            <Footer />
        </Fragment>
    )
};

export default HomePage4;