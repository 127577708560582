import React from 'react'
import sImg1 from '../../images/story/how-we-meet-dhwedding-1.webp'
import sImg2 from '../../images/story/how-we-meet-dhwedding-2.webp'
import sImg3 from '../../images/story/how-we-meet-dhwedding-3.webp'

import shape1 from '../../images/story/s-shape1.png'
import shape2 from '../../images/story/s-shape2.png'
import SectionTitle from '../SectionTitle'


const StorySection4 = (props) => {
    return (

        <section className="wpo-story-section-s4 section-padding" id="story">
            <div className="container">
                <SectionTitle subTitle={'Nuestra Historia'} MainTitle={'¿Cómo sucedió?'} />
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="story-timeline">
                            <div className="round-shape"></div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder right-align-text left-site">
                                        <img src={sImg1} alt="" className="img img-responsive" />
                                        <div className="story-shape-img-1">
                                            <img src={shape1} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text">
                                        <span className="date">30 de Septiembre, 2016</span>
                                        <h3>¿Cómo nos conocimos?</h3>
                                        <p>El destino nos reunió en una fiesta de la facultad. Lo que comenzó como conversaciones sobre tutorías de cálculo, rápidamente se convirtió en el capítulo inicial de nuestra historia de amor. Pronto descubrimos que entre ecuaciones y fórmulas, nuestros corazones encontraron la verdadera solución: el amor.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12 order-lg-1 order-2 text-holder right-heart">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text right-align-text">
                                        <span className="date">28 de Enero, 2023</span>
                                        <h3>Se propuso, Dije si</h3>
                                        <p>Sigo preguntándome cómo nunca sospeché lo que sucedería esa noche. Eres el único que puede convertir un momento como ese en uno de mis mejores recuerdos.</p>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 order-lg-2 order-1">
                                    <div className="img-holder right-align-img">
                                        <img src={sImg2} alt="" className="img img-responsive" />
                                        <div className="story-shape-img-1">
                                            <img src={shape2} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder video-holder left-site">
                                        <img src={sImg3} alt="" className="img img-responsive" />
                                        <div className="story-shape-img-1">
                                            <img src={shape1} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text">
                                        <span className="date"> 14 de Diciembre, 2023</span>
                                        <h3>Nuestro día de compromiso</h3>
                                        <p>Listos para dar el siguiente paso juntos, compartiendo eventos que solo se repiten una vez en la vida con la persona que amamos profundamente.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col offset-lg-6 col-lg-6 col-12 text-holder">
                                    <div className="round-shape-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StorySection4;