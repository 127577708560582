import React from 'react';
import SectionTitle from "../SectionTitle";
import gftImg from "../../images/gift-table/gift-card.png"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contactpage = () => {

    const copyToClipboard = (text) => {
        // Crea un elemento de entrada de texto temporal
        const textArea = document.createElement('textarea');
        textArea.value = text;

        // Añade el elemento de entrada de texto al DOM
        document.body.appendChild(textArea);

        // Selecciona y copia el texto
        textArea.select();
        document.execCommand('copy');

        // Elimina el elemento de entrada de texto temporal
        document.body.removeChild(textArea);

        // Muestra una notificación o realiza cualquier otra acción que desees
        handleAmazonLinkClick();
    };

    const handleAmazonLinkClick = () => {
        // Aquí puedes realizar cualquier otra acción que desees, como copiar el enlace al portapapeles.
        // Luego, muestra el toast.
        toast.success('Código copiado', {
            position: "bottom-right", // Puedes personalizar la posición del toast
            autoClose: 1000, // Puedes personalizar la duración de la notificación
        });
    };


    return (
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <SectionTitle subTitle={''} MainTitle={'Mesa de Regalos'} />
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-4 col-md-4 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <img src={gftImg} alt="Amazon Gift Card" />
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Sobre</h2>
                                            <p>El día del evento</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-4 col-md-4 col-12">
                                    <a href='https://www.amazon.com.mx/wedding/registry/3RMSJ6PJ6OJRK' target="_blank">
                                        <div className="office-info-item">
                                            <div className="office-info-icon">
                                                <img src={gftImg} alt="Amazon Gift Card" />
                                            </div>
                                            <div className="office-info-text">
                                                <h2>Amazon</h2>
                                                <p>Presiona para abrir el enlace</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col col-xl-4 col-lg-4 col-md-4 col-12">
                                <a href='https://mesaderegalos.liverpool.com.mx/milistaderegalos/51129164' target="_blank">
                                <div className="office-info-item">
                                            <div className="office-info-icon">
                                                <img src={gftImg} alt="Liverpool Gift Card" />
                                            </div>
                                            <div className="office-info-text">
                                                <h2>Liverpool</h2>
                                                <p>Presiona para abrir el enlace</p>
                                            </div>
                                        </div>

                                </a>
                                    {/* <div className="office-info-item with-hover" onClick={() => copyToClipboard(51129164)}> 
                                        <div className="office-info-icon">
                                            <img src={gftImg} />
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Liverpool</h2>
                                            <p>Código - 51129164</p>
                                        </div>
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )

}

export default Contactpage;
