import React from 'react'
import { Slide } from "react-awesome-reveal";
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/event/event-ceremony-1.webp'
import sImg2 from '../../images/event/event-reception-1.webp'
import sImg3 from '../../images/event/event-reception-1.webp'
import LocationMap from './Modal'


const Events = [
    {
        Simg: sImg1,
        title: 'La ceremonia',
        li1: 'Jueves, 14 de Diciembre, 2023 6:00 PM – 7:00 PM',
        li2: '5 de Mayo, Centro, 64000 Monterrey, N.L.',
        li3: '81 1158 2810',
        animation: '1200',
        address: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3595.9447553654327!2d-100.31242682356951!3d25.67312597740773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662958a75132f6f%3A0xa9d82c23fa5b8a63!2sParroquia%20del%20Sagrado%20Coraz%C3%B3n%20de%20Jes%C3%BAs!5e0!3m2!1ses-419!2smx!4v1698980963513!5m2!1ses-419!2smx'
    },
    {
        Simg: sImg2,
        title: 'La recepción',
        li1: 'Jueves, 14 de Diciembre, 2023 8:00 PM – 1:00 AM',
        li2: 'Ignacio Aldama 508, Centro, 66400 San Nicolás de los Garza, N.L.',
        li3: '',
        animation: '1400',
        address: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3593.34832583565!2d-100.29283882356737!3d25.759055877353486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662949a70feb723%3A0x2030a79e963a36ad!2sSan%20Nicolas%20Eventos!5e0!3m2!1ses-419!2smx!4v1698981620772!5m2!1ses-419!2smx'
    },
]

const EventSectionS3 = (props) => {
    return (
        <section className="wpo-event-section-s3 section-padding" id="event">
            <div className="container">
                <SectionTitle subTitle={'Nuestra Boda'} MainTitle={'¿Cuándo y dónde?'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-6 col-md-6 col-12" key={eitem}>
                                <Slide direction="up" duration={event.animation} triggerOnce="true">
                                    <div className="wpo-event-item">
                                        <div className="wpo-event-img">
                                            <div className="wpo-event-img-inner">
                                                <img src={event.Simg} alt="" />
                                            </div>
                                        </div>
                                        <div className="wpo-event-text">
                                            <div className="title">
                                                <h2>{event.title}</h2>
                                            </div>
                                            <ul>
                                                <li>{event.li1}</li>
                                                <li>{event.li2}</li>
                                                <li>{event.li3}</li>
                                                <li><LocationMap address={event.address} /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSectionS3;