import React, { useState } from 'react';
import axios from 'axios';
import { Stepper, Step, StepLabel, Button, Alert } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RSVPTable from './RSVPTable';

const RSVPForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [forms, setForms] = useState({
    code: '',
    name: '',
    email: '',
    date: '',
    guest: '',
    meal: '',
    reason: '',
  });
  const [showResults, setShowResults] = useState(false);
  const [selectedNames, setSelectedNames] = useState([]);
  const [validationMessage, setValidationMessage] = useState('');

  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    setValidationMessage('');
  };

  const submitHandler = (e) => {
    e.preventDefault();

    // Validar si el campo de código está vacío
    if (forms.code === '') {
      setValidationMessage('El código es requerido');
      return; // Salir de la función para evitar la llamada a la API
    }

    // Primera petición para validar el código
    axios.get(`https://alexvidal05.pythonanywhere.com/get-confirmation/${forms.code}`)
      .then((response) => {
        if (response.data && !response.data.hasOwnProperty('alert') && response.data.message === 'Código válido para confirmación') {
          // Código válido, ahora hacer la segunda petición para obtener resultados
          axios.get(`https://alexvidal05.pythonanywhere.com/get_result/${forms.code}`)
            .then((response) => {
              if (response.data && response.data.alert !== 'No results found') {
                const resultsList = response.data.map((result) => ({
                  name: result.name,
                  guest_code: result.guest_code,
                  guest_identifier: result.guest_identifier,
                }));
                setSelectedNames(resultsList);
                setShowResults(true);
                setActiveStep(1);
              }else {
                setValidationMessage('No hay coincidencias con este código, asegúrate de que sea correcto');
              }
            })
            .catch((error) => {
              console.error('Error al obtener datos de la API:', error);
            });
        } else if (response.data && response.data.message === 'Ya se realizó una confirmación con este código previamente') {
          setValidationMessage('Ya se realizó una confirmación con este código previamente');
        } else {
          setValidationMessage('No hay coincidencias con este código, asegúrate de que sea correcto');
        }
      })
      .catch((error) => {
        setValidationMessage('Se presentó un error al validar el código');
      });
  };

  return (
    <div>
      <Stepper activeStep={activeStep}>
        <Step>
          <StepLabel>Validación de código</StepLabel>
        </Step>
        <Step>
          <StepLabel>Confirmación</StepLabel>
        </Step>
      </Stepper>

      {activeStep === 0 && (
        <form onSubmit={submitHandler} className="contact-validation-active">
          <p className='wpo-confirmation-section text-style'>Introduce aquí tu código de invitado para continuar con la confirmación:</p>
          <input
            value={forms.code}
            type="text"
            name="code"
            onBlur={changeHandler}
            onChange={changeHandler}
            placeholder="Código de Invitado"
          />
          <Button className='wpo-confirmation-section button' type="submit" variant="contained" color='secondary' startIcon={<SearchIcon />}>
            Buscar
          </Button>
          {validationMessage && (
            <Alert severity="error">{validationMessage}</Alert>
          )}
        </form>
      )}

      {activeStep === 1 && showResults && (
        <RSVPTable selectedNames={selectedNames} />
      )}
    </div>
  );
};

export default RSVPForm;
