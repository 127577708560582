import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const RSVPForm = () => {
  const [forms, setForms] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    selectedGuests: [],
    meal: '',
    textarea: '',
  });
  const [validator] = useState(new SimpleReactValidator({
    className: 'errorMessage'
  }));

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setForms({ ...forms, [name]: value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const handleGuestSelection = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    setForms({ ...forms, selectedGuests: selectedOptions });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      console.log('Datos del formulario:', forms);
      // Aquí puedes enviar los datos al servidor o realizar cualquier otra acción necesaria.
      setForms({
        name: '',
        email: '',
        phone: '',
        date: '',
        selectedGuests: [],
        meal: '',
        textarea: '',
      });
    } else {
      validator.showMessages();
    }
  };

  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className={`card ${forms.selectedGuests.length > 0 ? 'green' : 'red'}`}>
      <form onSubmit={submitHandler} className="contact-validation-active">
        <div className="row">
          {/* ... Campos de nombre, email, teléfono y fecha (como en tu código original) */}
          <div className="col col-lg-6 col-12">
            <div className="form-field">
              <select
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => handleGuestSelection(e)}
                value={forms.selectedGuests}
                name="selectedGuests"
                multiple
                className="form-control"
              >
                <option>Selecciona los invitados que asistirán:</option>
                <option>Invitado 1</option>
                <option>Invitado 2</option>
                <option>Invitado 3</option>
                {/* Agrega más invitados según sea necesario. */}
              </select>
              {validator.message('selectedGuests', forms.selectedGuests, 'required')}
            </div>
          </div>
          <div className="col col-lg-6 col-12">
            <div className="form-field">
              <select
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                value={forms.meal}
                type="text"
                className="form-control"
                name="meal"
              >
                <option>Meal Preferences</option>
                <option>Chicken Soup</option>
                <option>Mutton Kabab</option>
                <option>Chicken BBQ</option>
                <option>Mix Salad</option>
                <option>Beef Ribs</option>
              </select>
              {validator.message('meal', forms.meal, 'required')}
            </div>
          </div>
          <div className="col col-lg-12 col-12">
            <div className="form-field">
              <textarea
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                value={forms.textarea}
                type="text"
                className="form-control"
                name="textarea"
                placeholder="Message"
              />
              {validator.message('textarea', forms.textarea, 'required')}
            </div>
          </div>
        </div>
        <div className="submit-area">
          <button type="submit" className="theme-btn">
            Send An Inquiry
          </button>
        </div>
      </form>
    </div>
  );
};

export default RSVPForm;
