import React, { useState } from 'react';
import {
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableBody,
    Checkbox,
    Button,
    Alert,
} from '@mui/material';
import axios from 'axios';

const RSVPTable = ({ selectedNames }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [isConfirmationSuccess, setIsConfirmationSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleRowClick = (name) => {
        if (selectedRows.includes(name)) {
            setSelectedRows(selectedRows.filter((row) => row !== name));
        } else {
            setSelectedRows([...selectedRows, name]);
        }
    };

    const handleConfirm = () => {
        const isConfirmed = window.confirm('¿Estás seguro de tus invitados seleccionados?');
        if (isConfirmed) {
            const payload = selectedNames.map((item) => {
                const rsvpValue = selectedRows.includes(item.name) ? 1 : 0;

                return {
                    confirmation_code: item.guest_code,
                    rsvp: rsvpValue,
                    user_id: item.guest_identifier,
                };
            });

            axios
                .post('https://alexvidal05.pythonanywhere.com/guests-confirmation', payload)
                .then((response) => {
                    if (response.data && !response.data.hasOwnProperty('alert') && response.data.message === 'Registros procesados con éxito') {
                        setIsConfirmationSuccess(true);
                    } else {
                        setErrorMessage('Error al guardar la confirmación');
                    }
                })
                .catch((error) => {
                    setErrorMessage('Error al guardar la confirmación');
                });
        } else {
            setSelectedRows([]);
        }
    };

    return (
        <div className='wpo-confirmation-section container-3'>
            {isConfirmationSuccess ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Alert severity="success" className='wpo-confirmation-section alert-confirmation'>
                        Gracias por tu confirmación
                    </Alert>
                </div>
            ) : (
                <div className='wpo-confirmation-section container'>
                    <p className='wpo-confirmation-section text-style-2'>Selecciona los nombres de quienes asistirán</p>
                    <TableContainer>
                        <Table>
                            <TableHead className='wpo-confirmation-section table-head'>
                                <TableRow>
                                    <TableCell>Invitado</TableCell>
                                    <TableCell>¿Asistirá?</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedNames.map((item) => (
                                    <TableRow
                                        key={item.name}
                                        onClick={() => handleRowClick(item.name)}
                                        className={`${selectedRows.includes(item.name) ? 'wpo-confirmation-section trow-color' : ''
                                            }`}                  >
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>
                                            <Checkbox checked={selectedRows.includes(item.name)} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {errorMessage && (
                        <Alert severity="error" className='wpo-confirmation-section alert-confirmation'>
                            {errorMessage}
                        </Alert>
                    )}
                    <Button variant="contained" color="primary" onClick={handleConfirm} className='wpo-confirmation-section button'>
                        Confirmar invitados
                    </Button>
                </div>
            )}
        </div>
    );
};

export default RSVPTable;
